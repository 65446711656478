import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-active"
export default class extends Controller {
  connect() {
  }

  toggle () {
    let subtitle = document.getElementById("subtitle");
    let spinner_box = document.getElementById("spinner_box");

    if (spinner_box.classList.contains("invisible")) {
      spinner_box.classList.remove("invisible")
    }

    subtitle.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  untoggle () {
    let spinner_box = document.getElementById("spinner_box");

    if (spinner_box.classList.contains("invisible") != true) {
      spinner_box.classList.add("invisible")
    }
  }
}
