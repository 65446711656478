import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "box", "header" ]

  connect() {
  }

  dismiss (event) {
    if (this.hideCondition) {
      this.hide()
    }
  }

  hideCondition () {
    this.boxTarget === event.target || this.headerTarget.contains(event.target)
  }

  hide () {
    this.containerTarget.classList.add('hidden')
  }
}
