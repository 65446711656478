import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-overlay"
export default class extends Controller {
  connect () {
    console.log("connected")
  }

  add () {
    let product_overlay = document.getElementById("product_overlay")

    console.log(product_overlay)
    if (product_overlay != undefined && product_overlay.classList.contains("hidden")) {
      product_overlay.classList.remove("hidden")
    }
  }
}
