import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disappear"
export default class extends Controller {
  static values = {
    delay: Number,
    animateOutClass: String,
    animateInClass: String
  }
  connect () {
    console.log("connect")
    this.timeout = setTimeout(() => {
          console.log("timer")
      // if (this.element.classList.contains(this.animateInClassValue ?? "animate__slideInRight")) {
      //   this.element.classList.remove(this.animateInClassValue ?? "animate__slideInRight")
      // }

      // this.element.classList.add("animate__animated")
      console.log(this.animateOutClassValue ?? "animate__slideOutRight")
      this.element.classList.add(this.animateOutClassValue ?? "animate__slideOutRight")
      this.element.remove()
    }, this.delayValue || 1000)
  }
}
