import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remover"
export default class extends Controller {
  static targets = ["removable"]

  remove () {
    if (this.removableTarget !== undefined) {
      this.removableTarget.remove()
    } else {
      this.element.remove()
    }

  }
}
