import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static values = {
    toggable: String
  }

  connect () {
    console.log(document.getElementById(this.toggableValue))
  }

  perform () {
    // console.log(this.toggableValue)
    // let modal = document.getElementById(this.toggableValue)

    // console.log(el)
    // if (el.classList.contains("hidden") == true) {
    //   el.classList.remove("hidden")
    // } else {
    //   el.classList.add("hidden")
    // }
  }

  show () {
    let modal = document.getElementById(this.toggableValue)
    modal.showModal()
  }

  hide () {
    console.log("hide")
    let modal = document.getElementById(this.toggableValue)
    modal.close()
  }
}
