import Cookies from 'js-cookie'

const recommendedOfferListener = () => {
  const el = document.querySelector('#recommended-trigger')

  if (el) {
    const observer = new window.IntersectionObserver(([entry]) => {

      if (entry.isIntersecting == true) {
        const quiz_card = document.getElementById('quiz-card')
        const quiz_area = document.getElementById('question_overlay')
        const quizCloseButton = document.getElementById("quiz-close-button")
        const bottom_quiz_area = document.getElementById('bottom_quiz_area')

        if (quizCloseButton != undefined) {
          quizCloseButton.remove()
        }
        quiz_area.classList.add("mx-auto", "max-w-5xl")
        quiz_area.classList.remove("absolute", "max-w-2xl", "shadow-lg")
        quiz_card.classList.add("static", "mx-auto")
        quiz_card.classList.remove("sticky", "shadow-lg")

        bottom_quiz_area.appendChild(quiz_area)
      }

      if (entry.isIntersecting == true && Cookies.get("recommended-offer-shown") != "true") {
      // if (entry.isIntersecting == true) {

        const ro = document.querySelector('#recommended-offer')

        ro.classList.add('slide-up')
        ro.classList.add('top-full')
        ro.classList.remove('hidden')
        ro.classList.add('top-0')

        Cookies.set("recommended-offer-shown", "true")

        observer.unobserve(entry.target);

        return
      }
    }, {
      root: null,
      threshold: 0.2, // set offset 0.1 means trigger if atleast 10% of element in viewport
    })

    observer.observe(el);
  }

}



// document.addEventListener("load", onLoad)
// document.addEventListener("turbolinks:load", onLoad)
document.addEventListener("turbo:load", recommendedOfferListener)
// document.removeEventListener(type: DOMString, callback: EventListener, capture?: boolean)
