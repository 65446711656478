import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "toggable" ]

  connect () {
    useClickOutside(this)
  }


  clickOutside (event) {
    if (this.toggableTarget.classList.contains('hidden') == false) {
      this.toggableTarget.classList.add('hidden');
    }
  }

  toggle () {
    if (this.toggableTarget.classList.contains('hidden') == true) {
      this.toggableTarget.classList.remove('hidden');
    } else {
      this.toggableTarget.classList.add('hidden');
    }

  }
}
