// Entry point for the build script in your package.json
// import "./controllers"
// import 'animate.css';

// import "./controllers/show_hide_controller.js"
import "./controllers/front.js"

// import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "./channels"
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"

// import "./packs/gtm.js"
import "./packs/recommended_offer.js"


const application = Application.start()

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
