import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="controller:show-hide"
export default class extends Controller {
  static targets = [ "content", "toggler", "closeIcon", "openIcon" ]
  connect() {
  }

  toggle () {
    if (this.contentTarget.classList.contains("hidden")) {
      if (this.contentTarget.dataset.inAnimationClass) {
        this.contentTarget.classList.add(this.contentTarget.dataset.inAnimationClass)
      } else {
        this.contentTarget.classList.remove("hidden")
      }

      if (this.hasCloseIconTarget) {
        this.closeIconTarget.classList.remove("hidden")
      }

      if (this.hasOpenIconTarget) {
        this.openIconTarget.classList.add("hidden")
      }

    } else {
      if (this.contentTarget.dataset.outAnimationClass) {
        this.contentTarget.classList.add(this.contentTarget.dataset.outAnimationClass)
      } else {
        this.contentTarget.classList.add("hidden")
      }


      if (this.hasCloseIconTarget) {
        this.closeIconTarget.classList.add("hidden")
      }

      if (this.hasOpenIconTarget) {
        this.openIconTarget.classList.remove("hidden")
      }

    }
  }

  inAnimation () {
    if (this.contentTarget.dataset.inAnimationClass) {
      this.contentTarget.dataset.inAnimationClass
    } else {
      "animate__slideInUp"
    }

  }

  outAnimation () {
    if (this.contentTarget.dataset.outAnimationClass) {
      this.contentTarget.dataset.outAnimationClass
    } else {
      "animate__slideOutDown"
    }
  }
}
