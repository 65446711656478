// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "alert", "form" ]

  submit (event) {
    event.preventDefault()
    this.alertTarget.classList.remove('invisible')
    console.log(this.formTarget)
    this.formTarget.requestSubmit()
  }

  click () {
    this.alertTarget.classList.remove('hidden')
  }

  success () {
    this.alertTarget.classList.add('invisible')
  }

}
